.hover50 {
  opacity: 0.5 !important;
  &:hover {
    opacity: 1 !important;
  }
}

/* Quill editor global overrides for ra-input-rich-text. */
.ql-editor {
  min-height: 200px;
}

.warningLabel {
  color: maroon !important;
}

.small-image {
  max-width: 310px;
}

.bold {
  font-weight: bold;
}

.ColorInput-popup {
  position: absolute;
  z-index: 2;
}

.ColorInput-cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ra-input.ra-input-tisha_bav_program .program_table {
  float: right;
}
