.content {
  padding: 0;
}

.formFields {
  margin-top: 50px;
}

.row {
  outline: 1px solid #eee;
  padding-right: 80px;
  padding-top: 10px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  z-index: 500;
  display: flex;
  flex: 1;
}

.rowBody {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
}

.rowField {
  width: 100%;
  // margin-top: 10px;
  margin-bottom: 10px;
}

.rowButtons {
  padding-left: 10px;
  padding-bottom: 10px;
}

.rowButton {
  margin-right: 40px;
}

.rowHandle {
  display: inline-block;
  padding-left: 80px;
  padding-right: 30px;
  cursor: grab;
}

.saveButton {
  margin-left: 95px;
  margin-right: 60px;
}

.subtitleText {
  display: inline;
  margin-left: 20px;
}

.toolbar {
  background-color: white;
  margin-top: 30px;
  flex: 1;
  display: flex;
}

.topbar {
  margin-top: 50px;
  margin-bottom: 25px;
  padding-left: 120px;
}
